<template>
    <div class="requests-pq-code-table position-relative">
        <div class="d-flex ">
            <template v-if="!simpleView && !modalMode">
                <div class="d-flex flex-column">
                    <template v-for="item in data?.pq_flight_legs">
                        <div class="pq-code-table-flight-airplane"
                             style="margin-top: 15px"
                             v-if="getRowSpanHeight(item.order)"
                             :style="`--height:calc(28px * ${getRowSpanHeight(item.order).rowspan != 1 ? getRowSpanHeight(item.order).rowspan : 1.3})`"
                        >
                            <div></div>
                            <div></div>
                        </div>
                    </template>
                </div>
            </template>
            <div class="overflow-auto pb-2">
                <table class="desktop-pq-table"
                       :class="[{'pq-table-simple-view white': simpleView}, tableClass]">
                    <thead>
                    <tr v-if="withAdditionalPqColumns">
                        <template v-for="item in [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]">
                            <th></th>
                        </template>
                        <th style="width: 76px">
                            <acms-v-icon class="ms-auto me-auto" name="suitcase"/>
                        </th>
                        <th class="text-center" style="width: 76px">
                            <acms-v-icon class="ms-auto me-auto" name="ticket"/>
                        </th>
                        <th class="text-center fz-14">TYPE</th>
                        <th class="text-center fz-14">PROGRAM/PCC</th>
                        <th class="text-center fz-14">FARE TYPE</th>
                        <th class="text-center fz-14">IS IT REAL?</th>
                    </tr>
                    </thead>
                    <tbody class="pq-table">
                    <template v-if="data">
                        <template v-if="!simpleView">
                            <template v-if="modalMode && data?.pq_flight_legs">
                                <template v-for="(item, itemIndex) in  data.pq_flight_legs">
                                    <template v-if="item.flight_details && item.flight_details.length > 1">
                                        <tr style="height: 58px" v-for="(detail, detailIndex) in item.flight_details">
                                            <td class="text-uppercase text-center">{{ detail.order ?? '' }}</td>
                                            <td>{{ detail.airline ?? ''  }}</td>
                                            <td>{{ detail.flightNumber ?? '' }}</td>
                                            <td>{{ detail.order ? item?.booking_class : '' }}</td>
                                            <td>{{  detail.order ? item?.departure_day_of_week : '' }}</td>
                                            <td>{{  detail.departureDate ?? "" }} <sup style="color: red"><b>{{getDaysDifference(detail.departureDate, item?.arrival_date)}}</b></sup></td>
                                            <td>{{  detail.from }}</td>
                                            <td>{{  detail.to  }}</td>
                                            <td>{{  item?.status ?? ""  }}</td>
                                            <td>{{ item?.quantity ?? ""}}</td>
                                            <td>{{  detail.timeFrom ?? '' }}</td>
                                            <td>{{  detail.timeTo ?? ''  }}</td>
                                            <td>{{  detail.flightDuration ?? ''  }}</td>
                                            <td class="text-center "
                                                :class="{'bg-warning-light': detailIndex > 0 }">
                                                <template v-if="detailIndex > 0">
                                                    {{ calculateWaitTime(detail.timeFrom, item.flight_details[detailIndex-1].timeTo ) }}
                                                </template>

                                            </td>

                                            <template v-if="getRowSpanHeight(item.order)">
                                                <template v-if="detailIndex === 0">
                                                <td class="bg-info-light text-center"
                                                    :rowspan="getRowSpanHeight(item.order).rowspan">
                                                    {{ getRowSpanHeight(item.order).duration }}
                                                </td>
                                                </template>
                                            </template>
                                            <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                                <td></td>
                                            </template>
                                            <template v-if="withAdditionalPqColumns && detailIndex === 0 ">
                                                <td class="p-0" style="width: 120px !important;">

                                                    <acms-v-form-field
                                                        :modeSelect="['0','1','2','3','4']"
                                                        placeholder="Choose"
                                                        hideHintInit
                                                        class="pq-table-row-select small-select small-select-baggage"
                                                        :modelValue="additionalDataBaggage[item.order].baggage_qnt"
                                                        @update:modelValue="(val)=>updateAdditionalDataBaggage(item.order,   val)"
                                                    />

                                                </td>
                                                <td class="p-0">
                                                    <div class="mx-auto" style="width: 76px;min-height: 42px">
                                                        <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                           :modeSelect="{
                                                                        options:getTicketsOptions,
                                                                        hideSelectedOption:true
                                                                    }"
                                                                           hideHintInit
                                                                           placeholder="0"
                                                                           class="pq-table-row-select small-select"
                                                                           :modelValue="locState.data[item.order].ticket"
                                                                           @update:modelValue="(val)=>updateAdditionalData(item.order,'ticket',  val)"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="p-0">
                                                    <div style="width: 152px;min-height:42px">
                                                        <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                           :modeSelect="backendOptions.pqTypesOptions"
                                                                           @update:modelValue="(val)=>updateAdditionalData(item.order,'type',  val)"
                                                                           :name="getErrorKey('type', itemIndex )"
                                                                           hideHintInit
                                                                           v-bind="getRowSelectPropsByKey(item, 'type')"
                                                        />
                                                    </div>
                                                </td>
                                                <td class="p-0">
                                                    <div style="width: 152px;min-height: 42px">
                                                        <template v-if="locState.tableSelectsShow">
                                                            <template v-if="getRowType(item)">
                                                                <template v-if="getRowType(item) == '1'">

                                                                    <acms-v-form-field
                                                                        :modeSelect="{
                                                                    search:true,
                                                                    searchAsync: 'infohub.getPccs',
                                                                    placeholderSelect:'Search',
                                                                    minSymbolsForSearch:2,
                                                                    optionKeyTitle:'pcc',
                                                                    toggleKeyTitle:'pcc',
                                                                    options:backendOptions.firstFivePccOptions,
                                                                   }"
                                                                        hideHintInit
                                                                        :name="getErrorKey('pcc', itemIndex )"
                                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'pcc',  val)"
                                                                        v-bind="getRowSelectPropsByKey(item, 'pcc')"
                                                                    />
                                                                </template>
                                                                <template v-if="getRowType(item) == '2'">
                                                                    <acms-v-form-field
                                                                        :modeSelect="{
                                                                    search:true,
                                                                    minSymbolsForSearch:2,
                                                                    searchAsync: 'infohub.getMileagesList',
                                                                    options:backendOptions.firstFiveMileageOptions,
                                                                   }"
                                                                        hideHintInit
                                                                        :name="getErrorKey('mileage', itemIndex )"
                                                                        v-bind="getRowSelectPropsByKey(item, 'mileage')"
                                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'mileage',  val)"
                                                                    />
                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <acms-v-form-field
                                                                    :modeSelect="[]"
                                                                    placeholder="Choose "
                                                                    class="pq-table-row-select  small-med-select"
                                                                    :name="getErrorKey('program-pcc', itemIndex)"
                                                                    :errors="additionalDataErrors"
                                                                    disabled
                                                                    hideHintInit
                                                                />
                                                            </template>
                                                        </template>
                                                    </div>
                                                </td>
                                                <td class="p-0">
                                                    <template v-if="locState.tableSelectsShow">
                                                        <template v-if="getRowType(item)">
                                                            <template v-if="getRowType(item) == '1'">
                                                                <acms-v-form-field
                                                                    :modeSelect="{
                                                                    options:['PVT', 'PUB', 'TUR', 'CRP']
                                                                   }"
                                                                    hideHintInit
                                                                    :name="getErrorKey('fare_type', itemIndex )"
                                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'fare_type',  val)"
                                                                    v-bind="getRowSelectPropsByKey(item, 'fare_type')"
                                                                />
                                                            </template>
                                                        </template>
                                                    </template>
                                                </td>
                                                <td class="p-0" style="width: 120px !important;">
                                                    <acms-v-form-field
                                                        :modeSelect="{
                                                                    options:['Real', 'Fake', 'Magic', 'Upgrade']
                                                                   }"
                                                        hideHintInit
                                                        :name="getErrorKey('is_real', itemIndex )"
                                                        @update:modelValue="(val)=>updateAdditionalData(item.order,'is_real',  val)"
                                                        v-bind="getRowSelectPropsByKey(item, 'is_real')"
                                                    />
                                                </td>
                                            </template>
                                            <template v-else>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </template>
                                        </tr>
                                    </template>
                                    <tr v-else style="height: 36px">
                                        <td class="text-uppercase text-center">{{ item.order }}</td>
                                        <td>{{ item.airline }}</td>
                                        <td>{{ item?.flight_number ?? '' }}</td>
                                        <td>{{ item?.booking_class ?? '' }}</td>
                                        <td>{{ item?.departure_day_of_week ?? '' }}</td>
                                        <td style="white-space: nowrap"> {{ item?.departure_date ?? '' }} <sup style="color: red;"><b>{{getDaysDifference(item.departure_date, item.arrival_date)}}</b></sup></td>
                                        <td>{{ item?.from }}</td>
                                        <td>{{ item?.to }}</td>
                                        <td>{{ item?.status ?? '' }}</td>
                                        <td>{{ item?.quantity ?? '' }}</td>
                                        <td>{{ item.time_from ?? '' }}</td>
                                        <td>{{ item.time_to ?? '' }}</td>
<!--                                        <td>{{ item.arrival_date ?? '' }}</td>-->
<!--                                        <td>{{ item.arrival_day_of_week ?? '' }}</td>-->
<!--                                        <td>{{ item.time_travel ?? '' }}</td>-->
                                        <td>{{ item.flight_details?.flightDuration ?? item.time_travel ?? '' }}</td>
                                        <td class="text-center "
                                            :class="{'bg-warning-light': item?.time_connection?.type?.name == 'LAYOVER'}">
                                            <template v-if="item?.time_connection?.type?.name == 'LAYOVER'">
                                                {{ item?.time_connection?.duration ?? '' }}
                                            </template>

                                        </td>

                                        <template v-if="getRowSpanHeight(item.order)">
                                            <td class="bg-info-light text-center"
                                                :rowspan="getRowSpanHeight(item.order).rowspan">
                                                {{ getRowSpanHeight(item.order).duration }}
                                            </td>
                                        </template>
                                        <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                            <td></td>
                                        </template>
                                        <template v-if="withAdditionalPqColumns ">
                                            <td class="p-0" style="width: 120px !important;">

                                                <acms-v-form-field
                                                    :modeSelect="['0','1','2','3','4']"
                                                    placeholder="Choose"
                                                    hideHintInit
                                                    class="pq-table-row-select small-select small-select-baggage"
                                                    :modelValue="additionalDataBaggage[item.order].baggage_qnt"
                                                    @update:modelValue="(val)=>updateAdditionalDataBaggage(item.order,   val)"
                                                />

                                            </td>
                                            <td class="p-0">
                                                <div class="mx-auto" style="width: 76px;min-height: 42px">
                                                    <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                       :modeSelect="{
                                                                        options:getTicketsOptions,
                                                                        hideSelectedOption:true
                                                                    }"
                                                                       hideHintInit
                                                                       placeholder="0"
                                                                       class="pq-table-row-select small-select"
                                                                       :modelValue="locState.data[item.order].ticket"
                                                                       @update:modelValue="(val)=>updateAdditionalData(item.order,'ticket',  val)"
                                                    />
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div style="width: 152px;min-height:42px">
                                                    <acms-v-form-field v-if="locState.tableSelectsShow"
                                                                       :modeSelect="backendOptions.pqTypesOptions"
                                                                       @update:modelValue="(val)=>updateAdditionalData(item.order,'type',  val)"
                                                                       :name="getErrorKey('type', itemIndex )"
                                                                       hideHintInit
                                                                       v-bind="getRowSelectPropsByKey(item, 'type')"
                                                    />
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <div style="width: 152px;min-height: 42px">
                                                    <template v-if="locState.tableSelectsShow">
                                                        <template v-if="getRowType(item)">
                                                            <template v-if="getRowType(item) == '1'">

                                                                <acms-v-form-field
                                                                    :modeSelect="{
                                                                    search:true,
                                                                    searchAsync: 'infohub.getPccs',
                                                                    placeholderSelect:'Search',
                                                                    minSymbolsForSearch:2,
                                                                    optionKeyTitle:'pcc',
                                                                    toggleKeyTitle:'pcc',
                                                                    options:backendOptions.firstFivePccOptions,
                                                                   }"
                                                                    hideHintInit
                                                                    :name="getErrorKey('pcc', itemIndex )"
                                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'pcc',  val)"
                                                                    v-bind="getRowSelectPropsByKey(item, 'pcc')"
                                                                />
                                                            </template>
                                                            <template v-if="getRowType(item) == '2'">
                                                                <acms-v-form-field
                                                                    :modeSelect="{
                                                                    search:true,
                                                                    minSymbolsForSearch:2,
                                                                    searchAsync: 'infohub.getMileagesList',
                                                                    options:backendOptions.firstFiveMileageOptions,
                                                                   }"
                                                                    hideHintInit
                                                                    :name="getErrorKey('mileage', itemIndex )"
                                                                    v-bind="getRowSelectPropsByKey(item, 'mileage')"
                                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'mileage',  val)"
                                                                />
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <acms-v-form-field
                                                                :modeSelect="[]"
                                                                placeholder="Choose "
                                                                class="pq-table-row-select  small-med-select"
                                                                :name="getErrorKey('program-pcc', itemIndex)"
                                                                :errors="additionalDataErrors"
                                                                disabled
                                                                hideHintInit
                                                            />
                                                        </template>
                                                    </template>
                                                </div>
                                            </td>
                                            <td class="p-0">
                                                <template v-if="locState.tableSelectsShow">
                                                    <template v-if="getRowType(item)">
                                                        <template v-if="getRowType(item) == '1'">
                                                            <acms-v-form-field
                                                                :modeSelect="{
                                                                    options:['PVT', 'PUB', 'TUR', 'CRP']
                                                                   }"
                                                                hideHintInit
                                                                :name="getErrorKey('fare_type', itemIndex )"
                                                                @update:modelValue="(val)=>updateAdditionalData(item.order,'fare_type',  val)"
                                                                v-bind="getRowSelectPropsByKey(item, 'fare_type')"
                                                            />
                                                        </template>
                                                    </template>
                                                </template>
                                            </td>
                                            <td class="p-0" style="width: 120px !important;">
                                                <acms-v-form-field
                                                    disabled="false"
                                                    :modeSelect="{
                                                                    options:['Real', 'Fake', 'Magic', 'Upgrade']
                                                                   }"
                                                    hideHintInit
                                                    :name="getErrorKey('is_real', itemIndex )"
                                                    @update:modelValue="(val)=>updateAdditionalData(item.order,'is_real',  val)"
                                                    v-bind="getRowSelectPropsByKey(item, 'is_real')"
                                                />
                                            </td>
                                        </template>
                                    </tr>
                                </template>
                            </template>
                            <template v-else>
                                <template v-for="item in data.pq_flight_legs">
                                    <template v-if="item.flight_details && item.flight_details.length > 1">
                                        <tr v-for="(detail, detailIndex) in item.flight_details">
                                            <td class="text-uppercase text-center">{{ detail.order ?? '' }}</td>
                                            <td>
                                <span>{{ detail?.airline ?? '' }}</span>
                                                <template  v-if="detail?.operated_by && !detail?.airline">
                                                      <span v-tooltip="'OPERATED BY: ' +detail.operated_by" style="color: red"><b>!!!</b></span>
                                                </template>
                                            </td>
                                            <td>{{ detail.flight_number ?? '' }}</td>
                                            <td>{{ detail.order ? item?.booking_class : '' }}</td>
                                            <td>{{  detail.order ? item?.departure_day_of_week : '' }}</td>
                                            <td style="white-space: nowrap"> {{ item?.departure_date ?? '' }} <sup style="color: red;"><b>{{getDaysDifference(item.departure_date, item.arrival_date)}}</b></sup></td>
                                            <td>
                                                <span>{{ detail?.from ?? '' }}</span>
                                            </td>
                                            <td>
                                                <span>{{ detail?.to ?? '' }}</span>

                                            </td>
                                            <td>{{  item?.status ?? ""  }}</td>
                                            <td>{{ item?.quantity ?? ""}}</td>
                                            <td>{{  detail.time_from ?? '' }}</td>
                                            <td>{{  detail.time_to ?? ''  }}</td>
<!--                                            <td>{{  ""  }}</td>-->
<!--                                            <td>{{  ""  }}</td>-->
                                            <td>{{  detail.flight_duration ?? ''  }}</td>
                                            <td class="text-center "
                                                :class="{'bg-warning-light': detailIndex > 0 }">
                                                <template v-if="detailIndex > 0 && detail.time_from && item.flight_details[detailIndex-1].time_to">
                                                    {{ calculateWaitTime(detail.time_from, item.flight_details[detailIndex-1].time_to ) }}
                                                </template>

                                            </td>
                                            <template v-if="getRowSpanHeight(item.order)">
                                                <template v-if="detailIndex === 0">
                                                    <td class="bg-info-light text-center"
                                                        :rowspan="getRowSpanHeight(item.order).rowspan">
                                                        {{ getRowSpanHeight(item.order).duration }}
                                                    </td>
                                                </template>
                                            </template>
                                            <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                                <td></td>
                                            </template>
                                            <td class="text-center">
                                                {{item.fare_type}}
                                            </td>
                                            <td>
                                                <acms-v-icon class="ms-auto me-auto   "
                                                             size="20"
                                                             :class="item?.baggage > 0 ? 'text-success' :'opacity-15' "
                                                             :quantity="item?.baggage"
                                                             quantityShow
                                                             quantitySmall
                                                             name="suitcase"/>
                                            </td>
                                            <td v-html="getStatusIcon(item.is_real)"></td>
                                            <td v-if="pccOrProgram">
                                                <acms-v-icon class="ms-auto me-auto"
                                                             size="20"
                                                             quantitySmall
                                                             :quantity="item?.ticket"
                                                             name="ticket"/>
                                            </td>
                                            <td v-if="pccOrProgram">
                                            <span class="white-space-nowrap">
                                                {{ item?.mileage?.title ?? item?.pcc?.pcc }}
                                            </span>
                                            </td>
                                            <td v-if="detail?.cabin_type">
                                            <span v-tooltip="'CABIN TYPE : ' + detail?.cabin_type" class="white-space-nowrap">
                                                {{getCabinCode(detail?.cabin_type)}}
                                            </span>
                                            </td>
                                            <td v-else >
                                            <span>
                                            </span>
                                            </td>
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td class="text-uppercase text-center">{{ item.order }}</td>
                                        <td>
                                <span
                                    style="white-space: nowrap">{{ item?.airline ?? '' }}  <b v-if="item?.flight_details[0]?.operated_by" style="color: red">!</b></span>
                                        </td>
                                        <td class=" ">{{ item?.flight_number ?? '' }}</td>
                                        <td class=" ">

                                            <span>
                                                 {{ item?.booking_class ?? '' }}
                                            </span>

                                        </td>
                                        <td class=" ">{{ item?.departure_day_of_week ?? '' }}</td>
                                        <td style="white-space: nowrap"> {{ item?.departure_date ?? '' }} <sup style="color: red;"><b>{{getDaysDifference(item.departure_date, item.arrival_date, item.created_at)}}</b></sup></td>
                                          <td class=" ">
                                <span>{{ item?.from ?? '' }}</span>
                                        </td>
                                        <td class="">
                              <span>{{ item?.to ?? '' }}</span>
                                        </td>
                                        <td class=" ">{{ item?.status ?? '' }}</td>
                                        <td class=" ">{{ item?.quantity ?? '' }}</td>
                                        <td class=" ">{{ item.time_from ?? '' }}</td>
                                        <td class=" ">{{ item.time_to ?? '' }}</td>
<!--                                        <td class=" ">{{ item.arrival_date ?? '' }}</td>-->
<!--                                        <td class=" ">{{ item.arrival_day_of_week ?? '' }}</td>-->
                                        <td class=" ">{{ item.time_travel ?? '' }}</td>
                                        <td class="text-center "
                                            :class="{'bg-warning-light': item?.time_connection?.type?.name == 'LAYOVER'}">
                                            <template v-if="item?.time_connection?.type?.name == 'LAYOVER'">
                                                {{ item?.time_connection?.duration ?? '' }}
                                            </template>
                                        </td>

                                        <template v-if="getRowSpanHeight(item.order)">
                                            <td class="bg-info-light text-center"
                                                :rowspan="getRowSpanHeight(item.order).rowspan ">
                                                {{ getRowSpanHeight(item.order).duration }}
                                            </td>
                                        </template>
                                        <template v-else-if="!getRowSpanHeight(item.order) && data.pq_flight_legs?.length == 1">
                                            <td></td>
                                        </template>
                                        <td class="text-center">
                                           {{item.fare_type}}
                                        </td>

                                        <td>
                                            <acms-v-icon class="ms-auto me-auto   "
                                                         size="20"
                                                         :class="item?.baggage > 0 ? 'text-success' :'opacity-15' "
                                                         :quantity="item?.baggage"
                                                         quantityShow
                                                         quantitySmall
                                                         name="suitcase"/>
                                        </td>
                                        <td v-html="getStatusIcon(item.is_real)"></td>
                                        <td v-if="pccOrProgram">
                                            <acms-v-icon class="ms-auto me-auto"
                                                         size="20"
                                                         quantitySmall
                                                         :quantity="item?.ticket"
                                                         name="ticket"/>
                                        </td>
                                        <td v-if="pccOrProgram">
                                            <span class="white-space-nowrap">
                                                {{ item?.mileage?.title ?? item?.pcc?.pcc }}
                                            </span>
                                        </td>
                                        <td  v-if="item?.flight_details[0]?.cabin_type">
                                            <span v-tooltip="'CABIN TYPE : ' + item?.flight_details[0]?.cabin_type" class="white-space-nowrap">
                                                {{getCabinCode(item?.flight_details[0]?.cabin_type)}}
                                            </span>
                                        </td>
                                        <td v-else>
                                            <span>
<!--                                                {{ (findBookingClass(item?.booking_class, item.booking_info.booking_class)).code}}-->
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                        <template v-if="simpleView && getSimpleViewData">
                            <template v-for="(item) in getSimpleViewData">
                                <tr>
                                    <td class="text-uppercase text-center">{{ item.order }}</td>
                                    <td>{{ item?.airline_info ?? '' }}</td>
                                    <td>{{ item?.departure_date ?? '' }}</td>
                                    <td>{{ item?.departure_day_of_week ?? '' }}</td>
                                    <td>{{ item?.fromTo ?? '' }}</td>
                                    <td>{{ item.statusQnt }}</td>
                                    <td>{{ item.time_from }}</td>
                                    <td>{{ item?.time_to ?? '' }}</td>
                                    <td>{{ item?.arrival_date ?? '' }}</td>
                                    <td>
                                        <acms-v-icon class="ms-auto me-auto"
                                                     size="20"
                                                     :class="item?.baggage > 0 ? 'text-success' :'opacity-15' "
                                                     :quantity="item?.baggage"
                                                     quantityShow
                                                     name="suitcase"/>
                                    </td>
                                    <td v-if="pccOrProgram">
                                        <acms-v-icon class="ms-auto me-auto"
                                                     size="20"
                                                     quantitySmall
                                                     :quantity="item?.ticket"
                                                     name="ticket"/>
                                    </td>
                                    <td v-if="pccOrProgram">
                                            <span class="white-space-nowrap">
                                                {{ item?.mileage?.title ?? item?.pcc?.pcc }}
                                            </span>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>export default {name: 'PartialParsedPqCodeTable',};</script>
<script setup="">

import {computed, onMounted, reactive, ref, watch} from 'vue';
import AcmsVFormField from "@ui/FormField/FormField.vue";
import {getRoute} from "@plugins/useRoutes";
import {cloneDeep} from "lodash";
import useBackendMiddlewarePropsService from "@services/BackendMiddlewarePropsService";
const props = defineProps({
    data: [Object, Array],
    simpleView: Boolean,
    tableClass: String,

    selectProgramOptions: Array,
    selectedPrograms: Object,
    selectedProgramsErrors: Object,
    selectedProgramsName: String,

    withAdditionalPqColumns: Boolean,
    additionalData: Object,
    additionalDataBaggage: Object,
    additionalDataErrors: Object,
    currentStep: [String, Number],

    showFromBack: Boolean,
    pccOrProgram: Boolean,

    modalMode: Boolean,
    modeDuplicate: Boolean,
});

const getStatusIcon = (status) => {
    switch (status) {
        case 'Real':
            return `<svg width="19" height="19" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z" fill="#4CAF51"></path> </g>
              </svg>`;
        case 'Fake':
            return `<svg width="19" height="19" fill="#EA580C" viewBox="-1.7 0 20.4 20.4" xmlns="http://www.w3.org/2000/svg" class="cf-icon-svg">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"><path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zm-6.804.01 3.032-3.033a.792.792 0 0 0-1.12-1.12L8.494 9.173 5.46 6.14a.792.792 0 0 0-1.12 1.12l3.034 3.033-3.033 3.033a.792.792 0 0 0 1.12 1.119l3.032-3.033 3.033 3.033a.792.792 0 0 0 1.12-1.12z"></path></g>
              </svg>`;
        case 'Magic':
            return `<svg  width="19" height="19" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ECC049">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"> <g> <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M15.224 15.508l-2.213 4.65a.6.6 0 0 1-.977.155l-3.542-3.739a.6.6 0 0 0-.357-.182l-5.107-.668a.6.6 0 0 1-.449-.881l2.462-4.524a.6.6 0 0 0 .062-.396L4.16 4.86a.6.6 0 0 1 .7-.7l5.063.943a.6.6 0 0 0 .396-.062l4.524-2.462a.6.6 0 0 1 .881.45l.668 5.106a.6.6 0 0 0 .182.357l3.739 3.542a.6.6 0 0 1-.155.977l-4.65 2.213a.6.6 0 0 0-.284.284zm.797 1.927l1.414-1.414 4.243 4.242-1.415 1.415-4.242-4.243z"></path> </g> </g>
              </svg>`;
        case 'Upgrade':
            return `<svg width="19" height="19" fill="#3276C3" viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg">
                <g id="VIP_bgCarrier" stroke-width="0"></g>
                <g id="VIP_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                <g id="VIP_iconCarrier"> <path d="M4 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 1l3 3h-2v3h-2v-3h-2l3-3z"></path> </g>
              </svg>`;
        default:
            return '';
    }
};

const emit = defineEmits(['update:selectedPrograms',
    'update:additionalData',
    'update:additionalDataBaggage',
]);
const {backendOptions} = useBackendMiddlewarePropsService()


const getErrorKey = (key, index) => `additional_data.${index}.${key}`

const getSimpleViewData = computed(() => {
    const array = props.data.pq_flight_legs
    if (!props.modalMode && array.length > 0) {

        return array.map((item) => {
            const {
                order = '',
                airline = '',
                flight_number = '',
                booking_class = '',
                departure_date = '',
                departure_day_of_week = '',
                from = '',
                to = '',
                status = '',
                quantity = '',
                time_from = '',
                time_to = '',
                arrival_date = '',
                baggage,
                ticket,
                mileage, pcc,
                flight_details = [],
            } = item

            return {
                order,
                airline_info: airline + flight_number + booking_class,
                departure_date,
                departure_day_of_week,
                fromTo: from + to,
                statusQnt: status + quantity,
                time_from,
                time_to,
                arrival_date,
                baggage,
                ticket,
                mileage, pcc,
                flight_details,
            }
        })
    }
    return false
})

const locState = reactive({
    data: cloneDeep(props.additionalData),
    dataBaggage: props.additionalDataBaggage,
    firstTicketOrder: {},
    tableSelectsShow: true,
    tableLoadingShow: false,
});

const setOrderForTickets = () => {
    locState.firstTicketOrder = {}
    Object.entries(locState.data).forEach(([itemOrderKey, itemValue]) => {
        if (!locState.firstTicketOrder[itemValue.ticket]) {
            locState.firstTicketOrder[itemValue.ticket] = itemOrderKey
        }
    })
}
const processDayOfWeek = (data) => {
    const daysOfWeekMap = {
        'M': 'MO',
        'T': 'TU',
        'W': 'WE',
        'Q': 'TH',
        'F': 'FR',
        'J': 'SA',
        'S': 'SU',
    };

    data.forEach(obj => {
        if (obj.departure_day_of_week) {
            obj.departure_day_of_week = daysOfWeekMap[obj.departure_day_of_week] || '';
        }
        if (obj.arrival_day_of_week) {
            obj.arrival_day_of_week = daysOfWeekMap[obj.arrival_day_of_week] || '';
        }
    });

    return data;
};
watch(() => props.additionalData, (val) => {
    if (Object.keys(val).length === 0) {
        locState.data = {}
        locState.dataBaggage = {}
    }
}, {deep: true})

const findBookingClass = (bookingCode, airlineData) => {
    if (airlineData && bookingCode) {
        // Перечисляем ключи, соответствующие различным классам бронирования
        const bookingClasses = ['basic_economy', 'business', 'economy', 'first', 'premium_economy'];

        for (let className of bookingClasses) {
            const classCodes = airlineData[className];
            if (classCodes && classCodes.includes(bookingCode)) {
                // Определяем код и полное название для возврата
                let code, tooltip;

                switch (className) {
                    case 'basic_economy':
                        code = 'BE';
                        tooltip = 'BASIC ECONOMY';
                        break;
                    case 'premium_economy':
                        code = 'PE';
                        tooltip = 'PREMIUM ECONOMY';
                        break;
                    default:
                        code = className.charAt(0).toUpperCase();
                        tooltip = className.toUpperCase();
                        break;
                }

                // Возвращаем объект с кодом и полным названием
                return { code, tooltip };
            }
        }
    }



    // Если код не найден возвращаем null
    return { code : '', tooltip: '' };
};

const getFlightSegmentRoutes = computed(()=>{
    return props?.data?.flight_segment_routes
})

const getPQFlightLegs = computed(()=>{
    return props?.data?.pq_flight_legs
})

const getRowSpanHeight = (order) => {
    const segments = getFlightSegmentRoutes.value
    const pqFlightLegs = getPQFlightLegs.value
    let additionalLength = 0;
    if (segments?.length > 0) {
        const find = segments.find((item => order == item.first_segment_number))
        let duration2 = undefined;
        if (find) {
            const {first_segment_number, last_segment_number, duration} = find
            if (duration.includes('h') && duration.includes('m')) {
                /* end of additional logic */
                const parts = duration.split(' ');
                const hours = parts[0].replace('h', '');
                const minutes = parts[1].replace('m', '');
                duration2 = `${hours}:${minutes}`;
            }

            for (let i = first_segment_number; i <= last_segment_number; i++) {
                const matchedPqFlightLegs = pqFlightLegs.filter((item) => item.order === i);
                matchedPqFlightLegs.forEach((item) => {
                    if (item.flight_details && item.flight_details.length > 1) {
                        additionalLength += item.flight_details.length - 1;
                    }
                });
            }



            return {
                rowspan: (last_segment_number - (first_segment_number - 1)) + additionalLength,
                duration: duration2 ?? duration
            }
        }
    }

    return false
}


const getTicketsOptions = computed(() => {
    const ticketsArr = [1, 2, 3, 4, 5]
    return ticketsArr.filter(item => item <= props.data.pq_flight_legs.length)
})
function calculateWaitTime(firstTime, secondTime) {
    // Конвертируем время в минуты
    function timeToMinutes(time) {
        let hours, minutes;
        if (time.endsWith('A') || time.endsWith('P')) {
            // 12-часовой формат с AM/PM
            hours = parseInt(time.slice(0, -3)) + (time.endsWith('P') ? 12 : 0);
            minutes = parseInt(time.slice(-3, -1));
        } else {
            // 24-часовой формат без AM/PM
            hours = parseInt(time.slice(0, -2));
            minutes = parseInt(time.slice(-2));
        }

        // Корректировка для полуночи и полудня в 12-часовом формате
        if (time.endsWith('A') && hours == 12) {
            hours = 0;
        }
        if (time.endsWith('P') && hours == 12) {
            hours = 12;
        }

        // Возвращаем общее количество минут
        return hours * 60 + minutes;
    }

    const firstMinutes = timeToMinutes(firstTime);
    const secondMinutes = timeToMinutes(secondTime);

    // Вычисляем разницу в минутах
    let difference = firstMinutes - secondMinutes;

    // Если разница отрицательная, прибавляем количество минут в сутках
    if (difference < 0) {
        difference += 24 * 60;  // 24 * 60 - количество минут в сутках
    }

    // Переводим разницу обратно в часы и минуты
    const hours = Math.floor(difference / 60);
    const minutes = difference % 60;
    // Возвращаем результат в формате HH:MM
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

const getRowType = (item) => {
    const dataOrder = locState.data[item.order]
    return dataOrder.type.id
}
const getRowSelectPropsByKey = (item, key) => {
    const orderItem = locState.data[item.order]
    const ticket = orderItem.ticket

    let modelValue = orderItem[key]
    let disabled = false
    if (locState.firstTicketOrder[ticket]) {
        disabled = locState.firstTicketOrder[ticket] != item.order
    }

    if (key == 'mileage' && props.modeDuplicate) {
        disabled = false
    }

    if (key == 'type' && props.modeDuplicate) {
        disabled = false
    }
    if (key === 'is_real') {
        disabled = false
    }


    return {
        modelValue,
        disabled,
        placeholder: "Choose",
        errors: props.additionalDataErrors,
        class: 'pq-table-row-select  small-med-select',
    }
}


// const collectAndFormatData = ({orderKey, dataKey, dataValue}) => {
//     const copyLocData = cloneDeep(locState.data)
//     const locDataEntries = Object.entries(copyLocData)
//     if (dataKey === 'ticket') {
//         const existTicketIndex = locDataEntries.findIndex(([itemOrderKey, itemValue]) => itemValue[dataKey] == dataValue)
//         if (existTicketIndex > -1) {
//             const existTicketValue = locDataEntries[existTicketIndex]?.[1]
//             const newEntries = locDataEntries.map((item, index) => {
//                 const [itemOrderKey, itemValue] = item
//                 if (existTicketIndex == index) {
//                     return item
//                 }
//                 if (itemOrderKey == orderKey) {
//                     if (itemValue.ticket != dataValue) {
//                         return [itemOrderKey, existTicketValue]
//                     }
//                     return [itemOrderKey, itemValue]
//                 }
//                 return item;
//             })
//             return Object.fromEntries(newEntries)
//         } else {
//             const ticketItem = cloneDeep(copyLocData[orderKey])
//             ticketItem[dataKey] = dataValue
//
//             if (backendOptions?.pqTypesOptions?.[0]) {
//                 ticketItem.type = backendOptions.pqTypesOptions?.[0]
//             }
//             ticketItem.pcc = null
//             ticketItem.mileage = null
//             copyLocData[orderKey] = ticketItem
//             return copyLocData
//         }
//
//     } else {
//
//         copyLocData[orderKey][dataKey] = dataValue;
//         const updatedDataItem = copyLocData[orderKey]
//         const copyUpdatedItem = cloneDeep(updatedDataItem)
//         let formattedEntries = locDataEntries.map((item) => {
//             const [itemOrderKey, itemValue] = item
//             if (itemValue.ticket == copyUpdatedItem.ticket && itemOrderKey != orderKey) {
//                 return [itemOrderKey, copyUpdatedItem]
//             }
//             return [itemOrderKey, itemValue];
//         })
//         if (dataKey === 'pcc') {
//             console.log('dataValue', dataValue);
//             const ticketsToUpdate = Object.values(copyLocData).filter(item => item.pcc !== null).map(item => item.ticket);
//
//             // Обновляем fare_type для объектов с найденными ticket и ненулевым pcc
//             Object.values(copyLocData).forEach(item => {
//                 if (ticketsToUpdate.includes(item.ticket) && item.pcc !== null ) {
//                     item.fare_type = item.pcc.default_fare_type;
//                 }
//             });
//         }
//
//         return Object.fromEntries(formattedEntries)
//     }
// }
// const collectAndFormatData = ({orderKey, dataKey, dataValue}) => {
//     const copyLocData = cloneDeep(locState.data);
//     const locDataEntries = Object.entries(copyLocData);
//     if (dataKey === 'ticket') {
//         const existTicketIndex = locDataEntries.findIndex(([itemOrderKey, itemValue]) => itemValue[dataKey] == dataValue)
//         if (existTicketIndex > -1) {
//             const existTicketValue = locDataEntries[existTicketIndex]?.[1]
//             const newEntries = locDataEntries.map((item, index) => {
//                 const [itemOrderKey, itemValue] = item
//                 if (existTicketIndex == index) {
//                     return item
//                 }
//                 if (itemOrderKey == orderKey) {
//                     if (itemValue.ticket != dataValue) {
//                         return [itemOrderKey, existTicketValue]
//                     }
//                     return [itemOrderKey, itemValue]
//                 }
//                 return item;
//             })
//             return Object.fromEntries(newEntries)
//         } else {
//             const ticketItem = cloneDeep(copyLocData[orderKey])
//             ticketItem[dataKey] = dataValue
//
//             if (backendOptions?.pqTypesOptions?.[0]) {
//                 ticketItem.type = backendOptions.pqTypesOptions?.[0]
//             }
//             ticketItem.pcc = null
//             ticketItem.mileage = null
//             ticketItem.fare_type = null
//             copyLocData[orderKey] = ticketItem
//             return copyLocData
//         }
//
//     } else {
//         // Обработка изменения других ключей кроме 'ticket'
//         copyLocData[orderKey][dataKey] = dataValue;
//         if (dataKey === 'pcc' && dataValue !== null) {
//             // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
//             const currentTicket = copyLocData[orderKey].ticket;
//             Object.values(copyLocData).forEach((item, idx) => {
//                 if (item.ticket === currentTicket) {
//                     copyLocData[idx+1].fare_type = dataValue?.default_fare_type ?? null;
//                 }
//             });
//         }
//         return copyLocData;
//     }
// };


const collectAndFormatData = ({orderKey, dataKey, dataValue}) => {
    const copyLocData = cloneDeep(locState.data);
    const locDataEntries = Object.entries(copyLocData);
    if (dataKey === 'ticket') {
        const existTicketIndex = locDataEntries.findIndex(([itemOrderKey, itemValue]) => itemValue[dataKey] === dataValue);
        if (existTicketIndex > -1) {
            const existTicketValue = locDataEntries[existTicketIndex]?.[1];
            const newEntries = locDataEntries.map((item, index) => {
                const [itemOrderKey, itemValue] = item;
                if (Number(itemOrderKey) === Number(orderKey)) {
                    return [itemOrderKey, { ...existTicketValue }];
                }
                return item;
            });
            return Object.fromEntries(newEntries);
        } else {
            const ticketItem = cloneDeep(copyLocData[orderKey])
            ticketItem[dataKey] = dataValue

            if (backendOptions?.pqTypesOptions?.[0]) {
                ticketItem.type = backendOptions.pqTypesOptions?.[0]
            }
            ticketItem.pcc = null
            ticketItem.mileage = null
            ticketItem.fare_type = null
            ticketItem.is_real = "Real"
            copyLocData[orderKey] = ticketItem
            return copyLocData
        }

    }
    else {
        // Обработка изменения других ключей кроме 'ticket'
        copyLocData[orderKey][dataKey] = dataValue;

        if (dataKey === 'pcc' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {

                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].fare_type = copyLocData[orderKey].pcc.default_fare_type ?? null;
                    copyLocData[idx+1].pcc = copyLocData[orderKey].pcc ?? null;
                }
            });
        }
        if (dataKey === 'fare_type' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {
                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].fare_type = copyLocData[orderKey].fare_type ?? null;
                }
            });
        }

        // if (dataKey === 'is_real' && dataValue !== null) {
        //     // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
        //     const currentTicket = copyLocData[orderKey].ticket;
        //     Object.values(copyLocData).forEach((item, idx) => {
        //         if (item.ticket === currentTicket) {
        //             copyLocData[idx+1].is_real = copyLocData[orderKey].is_real ?? "Real";
        //         }
        //     });
        // }

        if (dataKey === 'type' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {
                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].type = copyLocData[orderKey].type ?? null;
                }
            });
        }

        if (dataKey === 'mileage' && dataValue !== null) {
            // Если обновляем pcc, то нужно проверить и обновить fare_type только для текущего ticket
            const currentTicket = copyLocData[orderKey].ticket;
            Object.values(copyLocData).forEach((item, idx) => {
                if (item.ticket === currentTicket) {
                    copyLocData[idx+1].mileage = copyLocData[orderKey].mileage ?? null;
                }
            });
        }

        return copyLocData;
    }
};
// const getDaysDifference = (dateStr1, dateStr2) => {
//     if (!dateStr1 || !dateStr2) {
//         return '';
//     }
//     console.log('dateStr1', dateStr1, dateStr2);
//     // Определение текущего года
//     const currentYear = new Date().getFullYear();
//     const parseDate = (dateStr) => {
//         const months = {
//             JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
//             JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
//         };
//         const day = parseInt(dateStr.slice(0, 2), 10);
//         const month = months[dateStr.slice(2).toUpperCase()];
//
//         return new Date(currentYear, month, day);
//     };
//
//     const date1 = parseDate(dateStr1);
//     const date2 = parseDate(dateStr2);
//
//     // Вычисление разницы в днях без округления
//     const diffTime = date2 - date1;
//     const diffDays = diffTime / (1000 * 60 * 60 * 24);
//
//     // Возвращение разницы в днях со знаком
//     return diffDays > 0 ? `+${Math.floor(diffDays)}` : `${Math.ceil(diffDays)}`;
// };

const getDaysDifference = (dateStr1, dateStr2, created_at = new Date()) => {
    if (!dateStr1 || !dateStr2) {
        return '';
    }

    // Проверяем, если created_at - строка, преобразуем её в объект Date
    if (typeof created_at === 'string') {
        created_at = new Date(created_at);
    }

    // Получаем год, месяц и день из created_at
    let baseYear = created_at.getFullYear();
    const createdMonth = created_at.getMonth();
    const createdDay = created_at.getDate();

    const parseDate = (dateStr, year) => {
        const months = {
            JAN: 0, FEB: 1, MAR: 2, APR: 3, MAY: 4, JUN: 5,
            JUL: 6, AUG: 7, SEP: 8, OCT: 9, NOV: 10, DEC: 11
        };
        const day = parseInt(dateStr.slice(0, 2), 10);
        const month = months[dateStr.slice(2).toUpperCase()];
        return new Date(year, month, day);
    };

    let date1 = parseDate(dateStr1, baseYear);
    let date2 = parseDate(dateStr2, baseYear);

    // Проверка: если обе даты меньше даты created_at по месяцу и дню
    if (
        (date1.getMonth() < createdMonth || (date1.getMonth() === createdMonth && date1.getDate() < createdDay)) &&
        (date2.getMonth() < createdMonth || (date2.getMonth() === createdMonth && date2.getDate() < createdDay))
    ) {
        // Увеличиваем baseYear на 1, если обе даты меньше
        baseYear += 1;
        date1 = parseDate(dateStr1, baseYear);
        date2 = parseDate(dateStr2, baseYear);
    }

    // Проверка только по месяцу между date1 и date2
    if (date2.getMonth() < date1.getMonth()) {
        date2.setFullYear(date2.getFullYear() + 1); // Добавляем год, если месяц date2 меньше
    }

    // Устанавливаем время на полночь для обеих дат
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    // Вычисление разницы в днях
    const diffTime = date2 - date1;
    const diffDays = diffTime / (1000 * 60 * 60 * 24);

    if (diffDays === 0) {
        return '';
    }

    return diffDays > 0 ? `+${Math.floor(diffDays)}` : `${Math.ceil(diffDays)}`;
};





const getCabinCode = cabin => {
    switch (cabin) {
        case "ECONOMY":
            return "E";
        case "BUSINESS":
            return "B";
        case "PREMIUM":
            return "P";
        case "PREMIUM ECONOMY":
            return "P";
        case "FIRST":
            return "F";
        default:
            return "";
    }
};

const emitUpdatedAdditionData = () => {

    emit('update:additionalData', locState.data)
};
const updateAdditionalDataBaggage = (order, value) => {
    locState.dataBaggage[order].baggage_qnt = value
    emit('update:additionalDataBaggage', locState.dataBaggage)
}
const updateAdditionalData = (order, key, value, index = null) => {
    if (['mileage', 'pcc', 'type', 'ticket', 'fare_type', 'is_real'].includes(key)) {
        locState.tableSelectsShow = false
        locState.tableLoadingShow = true

        locState.data = collectAndFormatData(
            {
                orderKey: order,
                dataKey: key,
                dataValue: value
            })
        setOrderForTickets()
        setTimeout(() => {
            locState.tableSelectsShow = true
            emitUpdatedAdditionData()
        }, 0);
    }
}
onMounted(() => {
    if (props.additionalData) {
        if (!Object.values(props.additionalData).every(item => !item.pcc && !item.mileage)) {
            setOrderForTickets()
        }
    }

    if (props.modeDuplicate) {
        emitUpdatedAdditionData()
    }

})
</script>
<style lang="scss">
.requests-pq-code-table th {
    padding: 12px !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.desktop-pq-table {
    tr {
        td, th{
            padding: 8px 10px !important;
        }
    }
}

.pq-table-row-select {
    padding: 8px;

    &.small-select {
        .acms-v-select-toggle {
            width: 60px;
            min-width: 60px;
        }
        .acms-v-select-dropdown{
            width: 60px !important;
            min-width: 60px !important;
        }
    }
    &.small-select-baggage{
        .acms-v-select-toggle {
            width: 80px;
            min-width: 80px;
        }
        .acms-v-select-dropdown{
            width: 80px !important;
            min-width: 80px !important;
        }
    }

    &.small-med-select {
        .acms-v-select-toggle {
            width: 128px;
            min-width: 60px;
        }
    }

    .acms-v-select-toggle-placeholder {
        color: #4B5563;
    }
}
</style>
